import { useEffect } from 'react';

import Router from 'next/router';
import { NextPage } from 'next';

const RedirectNotFound: NextPage = () => {
  useEffect(() => {
    Router.push('/home');
  }, []);

  return null;
};

export default RedirectNotFound;
